html body {
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #000000;
}

#root {
  height: 100%;
}
