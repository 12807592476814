/* For the Components of Machine Learning Functions */

.sideBar {
  position: fixed;
  top: 4rem;
  right: 0;
  padding: 10px;
  border-right: 1px solid #111;
  /* background: #e1e1e1; */
}

.dropZone {
  flex: 0 0 auto;
  height: 40px;
  transition: 200ms all;
}

.dropZone:nth-of-type(2n) {
  /* display: none; */
}

.dropZone.horizontalDrag {
  width: 40px;
  height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.dropZone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
}

.trashDropZone.active {
  background: #e44d66;
  transition: 100ms all;
}

.column {
  width: 70vw;
}

.columns {
  display: flex;
  padding: 20px 0;
}

.columnContainer {
  display: flex;
}

.base {
  padding: 0.5rem 1rem;
  background-color: white;
}

.row {
  border: 1px solid red;
  padding: 0;
}

.trashDropZone {
  flex: 0 0 auto;
  width: 40px;
  height: auto;
  transition: 200ms all;
}

/* ML Components Container Area */
.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
  /* padding-top: 2rem !important; */
  top: 2rem;
  bottom: 2rem;
  background-color: #000000;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

/* 브라우저 전체 화면의 스크롤바 숨기기 */
body::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

/* 우측 사이드바 전용 스크롤바 모양 커스터마이징 */
.sideBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.sideBar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.sideBar::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/* 프로젝트 불러오기 & 서비스 사용량 전용 스크롤바 모양 커스터마이징 */
.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(231, 231, 231);
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #626669;
}

/* 프로젝트 불러오기 & 서비스 사용량 전용 스크롤바 모양 커스터마이징 */
.chatting-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(231, 231, 231);
  background-color: #f5f5f5;
}

.chatting-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.chatting-scroll::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.color-picker input {
  display: block;
  box-sizing: border-box;
  width: 90px;
  margin: 10px 55px 0;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  outline: none;
  font: inherit;
  text-transform: uppercase;
  text-align: center;
}

.color-picker input:focus {
  border-color: #4298ef;
}
